import { config } from "config";
import { JsonResponseError, store, Sum } from "contorller";
import { authController } from "contorller/auth/controller";
import { Auther } from "contorller/auth/types";
import {
  absoluteEarge,
  annualEarge,
  maxNumber,
  minNumber,
  picToPac,
  unixToDate,
} from "contorller/utils";
import { calcSpotOperation } from "../PAC/spot";
import {
  ControllerSimulation,
  PACResponse,
  PICRequest,
  PICResponse,
} from "../types";
import { calcPic, EarningMapSingleFund, PicEarnResponse } from "./fx";
import { OptionsPicGraph, picGraph, picResponseGraph } from "./graph";
import {
  InvestmentParams,
  InvestmentResult,
  picInvestment,
} from "./investment";
import { PICActions } from "./redux";
import {
  getRollingPic,
  optionsRollingPic,
  PICRollingResponse,
} from "./rolling-istogram";
import { Zoom } from "./zoom";

export interface PICController
  extends ControllerSimulation<PICRequest, PICResponse> {
  // calcs(requests:PICRequest[]):Promise<void>
  istogram(id: string, opts: optionsRollingPic): PICRollingResponse[];
  getFlags(): Date[];
  zoom(start: Date, end: Date): PICResponse[];
  investment(params: InvestmentParams): InvestmentResult;
  investments(params: InvestmentParams): InvestmentResult[];
  graph(optiosn: OptionsPicGraph): picResponseGraph[];
  earningForFund(pidId: string, start?: Date, end?: Date): PicEarnResponse[];
  getPac(): PACResponse;
  setPac(): void;
}

export const picController = (): PICController =>
  concretePicController.getInstance(authController().getAuther());

class concretePicController implements PICController {
  resp: PICResponse[];
  private auther: Auther;
  private pac?: PACResponse;
  private static _intance: concretePicController;
  private constructor(auther: Auther) {
    this.auther = auther;
    this.resp = [];

    store.subscribe(() => {
      if (this.pac) {
        //console.log("[UPDATE]", store.getState().pic.spots.operations);
        const spots = store.getState().pic.spots.operations;
        calcSpotOperation(this.getPac(), spots);
      }
    });
  }
  getName(): string {
    //FIXME: da togliere
    return "PIC";
  }
  csvDownload() {
    throw new Error("Method not implemented.");
  }
  jsonDownload() {
    if (this.resp.length === 0) throw new Error("non ho nessuna risposta pic");

    var dataStr =
      "data:text/json;charset=utf-8," +
      encodeURIComponent(JSON.stringify(this.resp[0]));
    var dlAnchorElem: HTMLAnchorElement = document.createElement("a");
    dlAnchorElem.setAttribute("href", dataStr);
    dlAnchorElem.setAttribute("download", "pic.json");
    dlAnchorElem.click();
  }

  getPac(): PACResponse {
    if (this.pac) {
      return this.pac;
    }
    throw new Error("non hai selezionato un pac");
  }
  setPac(): void {
    const [start, end] = this.getFlags();
    if (this.resp.length !== 1) {
      throw new Error(
        "non posso settare un pac quando mostri un confronto tra pic."
      );
    }

    let resp = [
      calcPic(
        {
          ...this.resp[0].request,
          initialInvestment: store.getState().pic.money,
        },
        this.resp[0].snaps
      ),
    ];
    this.pac = picToPac(Zoom(resp, start, end)[0]);
  }

  earningForFund(picId: string, start?: Date, end?: Date): PicEarnResponse[] {
    let response = this.resp.find((e) => e.id === picId);

    if (response) {
      return EarningMapSingleFund(response, start, end);
    } else {
      throw new Error("Non trovo il pic con questo id: " + picId);
    }
  }
  graph(optiosn: OptionsPicGraph): picResponseGraph[] {
    return picGraph(this.resp, optiosn);
  }
  zoom(start: Date, end: Date): PICResponse[] {
    return Zoom(this.resp, start, end);
  }

  investments(params: InvestmentParams): InvestmentResult[] {
    return [];
  }

  investment(params: InvestmentParams): InvestmentResult {
    const indexPic = this.resp.map((e) => e.id).indexOf(params.picId);
    if (indexPic === -1) throw new Error("non trovo la simulazione");

    let sim = this.resp[indexPic];
    return picInvestment(sim, params);
  }
  getFlags(): Date[] {
    return [
      unixToDate(store.getState().pic.flags.start),
      unixToDate(store.getState().pic.flags.end),
    ];
  }

  static getInstance(auther: Auther): concretePicController {
    if (!this._intance) {
      this._intance = new concretePicController(auther);
    }
    return this._intance;
  }

  clearAllResults(): void {
    store.dispatch(PICActions.reset());
    this.resp = [];
  }

  clearResult(id: string): void {
    this.resp = this.resp.filter((ed) => ed.id !== id);
  }

  getAllResults(): PICResponse[] {
    if (this.resp.length > 0) return this.resp;
    return [];
  }

  getResult(id: string): PICResponse | undefined {
    if (this.resp.map((e) => e.id).indexOf(id) === -1) return undefined;
    return this.resp.filter((e) => e.id === id)[0];
  }

  istogram(id: string, opts: optionsRollingPic): PICRollingResponse[] {
    if (this.resp.length !== 1) return [];
    if (this.resp.map((e) => e.id).indexOf(id) === -1) {
      throw new Error("Errore non trovo l'id richiesto dtra le simulazioni");
    }

    const resp = getRollingPic(this.resp.filter((e) => e.id === id)[0], opts);
    const absoluteAvg = Sum(resp.map((e) => e.absolute)) / resp.length;
    const years = opts.range.year;

    store.dispatch(
      PICActions.tableEarn({
        table: {
          years: opts.range.year,
          absolute: {
            min: minNumber(resp.map((e) => e.absolute)),
            max: maxNumber(resp.map((e) => e.absolute)),
            avg: absoluteAvg,
          },
          annual: {
            min: minNumber(resp.map((e) => e.annual)),
            max: maxNumber(resp.map((e) => e.annual)),
            avg: Math.pow(1 + absoluteAvg, 1 / years) - 1,
          },
        },
      })
    );

    return resp;
  }

  async calc(id: string, request: PICRequest): Promise<void> {
    // store.dispatch(PICActions.startSimulation())
    // console.log(request);
    // this.clearResult(id);

    if (request.start === request.end) {
      throw new Error(
        `Start e End della richista pic di simulazione coincidono ${request.start} ${request.end}`
      );
    }

    // This is for retrocompatiblity.
    const initialInvestment = request.initialInvestment;
    request.initialInvestment = {
      money: initialInvestment,
    } as any;

    const results = [];
    const response = await fetch(config().URL + "/pic/", {
      headers: await this.auther.getHeaderToken(),
      method: "POST",
      body: JSON.stringify(request),
    });
    if (response.status === 200) {
      // This is for retrocompatiblity.
      request.initialInvestment = initialInvestment;

      const data: PICResponse = await response.json();
      data.id = id;
      data.name = request.name;
      data.request = request;
      results.push(data);
      data.absolute = 0;
      data.annual = 0;
      if (data.snaps.length > 0) {
        let last = data.snaps[data.snaps.length - 1];
        let first = data.snaps[0];
        data.absolute = absoluteEarge(Sum(last.funds), Sum(first.funds));
        data.annual = annualEarge(
          Sum(last.funds),
          Sum(first.funds),
          Math.abs(
            new Date(last.unix * 1000).getFullYear() -
              new Date(first.unix * 1000).getFullYear()
          )
        );
      }
      // console.log("PIC", this.resp);
      this.clearResult(id);
      this.resp.push(...results);
      store.dispatch(
        PICActions.setFlags({
          start: data.snaps[0].unix,
          end: data.snaps[data.snaps.length - 1].unix,
          revision: true,
        })
      );
    } else {
      const data: JsonResponseError = await response.json();
      console.error(data);
      store.dispatch(PICActions.error({ error: data }));
    }
  }
}

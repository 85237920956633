import { useQuery } from "@tanstack/react-query";
import { RootState } from "contorller";
import { AuthState } from "contorller/auth/reducer";
import { UserBillingPlan } from "contorller/auth/types";
import { Metrics } from "contorller/fund/redux";
import {
  MetricsOptions,
  MetricsResult,
  portfolioController,
} from "contorller/portfolio/controller";
import { Portfolio } from "contorller/portfolio/types";
import { runEmpirichQuery, useEmpirichQuery } from "contorller/utils";
import { useSelector } from "react-redux";

export function useFreeRiskMetrics() {
  const auth = useSelector<RootState, AuthState>((state) => state.auth);

  return useEmpirichQuery<{ ticker: string; date: string; value: number }>(
    {
      endpoint: "/v1/metrics/free_risk",
      method: "GET",
    },
    ["freeRiskMetrics"],
    {
      staleTime: Infinity,
      enabled: auth.isLogged && auth.appIsLoad && [UserBillingPlan.PREMIUM,UserBillingPlan.PREMIUM_PLUS,UserBillingPlan.PREMIUM_TEST].includes(auth.user?.billingPlanEnum ?? UserBillingPlan.BASE),
    }
  );
}

export function usePortfolioMetrics(
  portfolio: Portfolio | null,
  timeHorizon: MetricsOptions,
  back_to_date: string | null = null
) {
  return useQuery<MetricsResult | null>(
    ["portfolio_metrics", portfolio?.id, timeHorizon, back_to_date],
    () =>
      portfolio != null
        ? portfolioController().getPortfolioMetrics(
            portfolio,
            timeHorizon,
            back_to_date
          )
        : null
  );
}

export function useMetricsFromHistoryical(nav: number[]) {
  const sum = nav.slice(-10).reduce((prev, next) => prev + next, 0);
  return useEmpirichQuery<Metrics>(
    {
      endpoint: "/v1/metrics/hist_calcs",
      method: "POST",
      body: {
        nav,
      },
    },
    ["metricsFromHistoryical", sum],
    {
      enabled: nav.length > 0,
    }
  );
}

export function usePortfolioMetricsCalc(
  portfolio: Portfolio,
  type?: "portfolio" | "customportfolio" | "tportfolio"
) {
  const ptf = portfolio.funds.map((f) => ({
    fundId: f.fund.id ?? f.id,
    weight: f.percentage,
  }));
  const percentage = ptf.reduce((prev, next) => prev + next.weight, 0);
  const key = JSON.stringify(ptf);
  return useQuery(
    ["portofolioMetricsCalc", ptf],
    () =>
      runEmpirichQuery<Response>({
        method: "POST",
        endpoint: "/v1/metrics/u_calcs",
        body: {
          ptf,
          startDate: portfolio.history.start,
          endDate: portfolio.history.end,
          riskFreeOptions: {
            ItalianBonds: 0,
            GermanBonds: 0,
            USABonds: 0,
            ESTR: 1,
          },
        },
      }),
    {
      enabled: type !== "tportfolio" && percentage > 0,
    }
  );
}

import { Sum } from "contorller";
import { absoluteEarge } from "contorller/utils";
import { GoalRequest, Investment, PACResponse } from "../types";
import { pacGetInvestmentTimeToTime } from "./spot";

export const absoluteEargePAC = (pac: PACResponse): number => {
  let lastDay = pac.snaps[pac.snaps.length - 1];
  let ctu = Sum(lastDay.funds);
  let cti = lastDay.investedMoney;
  return absoluteEarge(ctu, cti);
};

export const getInvested = (investments: Investment[]): number[] => {
  // calcolo l'investito nel tempo, a differenza della rata questo numero è cumulativo
  let cache = 0;
  const invested = investments.map((e, index) => {
    if (cache + e.money < 0) {
      let diff = cache + e.money;
      e.money = e.money + diff * -1;
      cache += e.money;
      return cache;
    } else {
      cache += e.money;
      return cache;
    }
  });
  return invested;
};

export const pacAvgAnnualRequest = (pac: PACResponse[]): GoalRequest => {
  let absoluteAvg = Sum(pac.map((e) => e.absolute)) / pac.length;

  let rates = pacGetInvestmentTimeToTime({ ...pac[0] });
  let days = pac[0].snaps;
  let last = days[days.length - 1];
  let cExit = (1 + absoluteAvg) * last.investedMoney;
  let request = {
    exit: cExit || 0,
    endInvestment: last.unix,
    money: rates.map((e) => e.money),
    investmentDate: rates.map((e) => e.unix),
  };

  // console.log(request);
  return request;
};

import { RootState } from "contorller";
import { AuthState } from "contorller/auth/reducer";
import { UserBillingPlan } from "contorller/auth/types";
import { useEmpirichQuery } from "contorller/utils";
import { useSelector } from "react-redux";

export interface UpAndDowns {
  values: {
    Unix: number;
    Close: number;
  }[];
}

export function useUpAndDowns(fundId?: string) {
  const _fundId = fundId ?? "19f2f64d-c589-59b3-82e1-67aed825e7da";
  // const _fundId = fundId ?? "378f6302-6b52-5474-8c22-6274cef7734a";
  const auth = useSelector<RootState, AuthState>((state) => state.auth);
  return useEmpirichQuery<UpAndDowns>(
    {
      endpoint: "/v1/metrics/fund_up_down",
      method: "POST",
      body: {
        fundId: _fundId,
        minDeltaPerc: 0.0,
        minDaysRest: 1000,
      },
    },
    ["upAndDowns", _fundId],
    {
      enabled: auth.isLogged && auth.appIsLoad && [UserBillingPlan.PREMIUM,UserBillingPlan.PREMIUM_PLUS,UserBillingPlan.PREMIUM_TEST].includes(auth.user?.billingPlanEnum ?? UserBillingPlan.BASE),
    }
  );
}

import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { JsonResponseError } from "contorller";
import { DateTime } from "luxon";
import { SpotOperation } from "../PAC/redux";
import { EarnTable } from "../types";

export interface PICSpotState {
  showWindowSpot: boolean;
  selectedFundId: string | null;
  operations: SpotOperation[];
  value: number;
}

export interface PICState {
  error?: JsonResponseError;
  loading: boolean;
  earnTable?: EarnTable;
  money: number;
  flags: {
    start: number;
    end: number;
    revision: number;
  };
  windowSize: number;
  graphOptions: {
    fundHoverId: string | null;
    fundIdsHidden: string[];
    picIdsHidden: string[];
    showSingleFunds: boolean;
  };
  spots: PICSpotState;
}

const initialState: PICState = {
  flags: { start: 0, end: 0, revision: 0 },
  windowSize: 0,
  loading: false,
  money: 100,
  graphOptions: {
    fundHoverId: null,
    fundIdsHidden: [],
    picIdsHidden: [],
    showSingleFunds: false,
  },
  spots: {
    showWindowSpot: false,
    operations: [],
    selectedFundId: null,
    value: 0,
  },
};

const picStore = createSlice({
  name: "pic",
  initialState,
  reducers: {
    error: (state, action: PayloadAction<{ error: JsonResponseError }>) => {
      state.error = action.payload.error;
      state.loading = false;
    },
    endSimulation: (state) => {
      state.loading = false;
    },
    setMoney: (state, action: PayloadAction<{ money: number }>) => {
      state.money = action.payload.money;
    },
    startSimulation: (state) => {
      state.loading = true;
      state.error = undefined;
      state.earnTable = undefined;
    },
    setWindowSize: (state, action: PayloadAction<{ windowSize: number }>) => {
      state.flags.end = DateTime.fromSeconds(state.flags.start)
        .plus({ months: action.payload.windowSize })
        .toSeconds();
      state.flags.revision++;

      state.windowSize = action.payload.windowSize;
    },
    tableEarn: (state, action: PayloadAction<{ table: EarnTable }>) => {
      state.earnTable = action.payload.table;
    },
    selectSpotFund: (
      state,
      action: PayloadAction<{ fundId: string | null }>
    ) => {
      state.spots.selectedFundId = action.payload.fundId;
    },
    toggleSingleFunds: (state) => {
      if (state.graphOptions.showSingleFunds) {
        state.graphOptions.showSingleFunds = false;
        state.graphOptions.picIdsHidden = [];
      } else {
        state.graphOptions.showSingleFunds = true;
      }
    },
    onlyPortfolioView: (state) => {
      state.graphOptions.showSingleFunds = false;
      state.graphOptions.picIdsHidden = [];
    },
    addFundIdHidden: (state, action: PayloadAction<{ id: string }>) => {
      state.graphOptions.fundIdsHidden.push(action.payload.id);
    },
    addPicIdHidden: (state, action: PayloadAction<{ id: string }>) => {
      state.graphOptions.picIdsHidden.push(action.payload.id);
    },
    removeAllFundIdsHidden: (state) => {
      state.graphOptions.fundIdsHidden = [];
    },
    setPicHoverFund: (state, action: PayloadAction<{ id: string | null }>) => {
      if (state.graphOptions.fundIdsHidden.includes(action.payload.id ?? "")) {
        state.graphOptions.fundHoverId = null;
      } else {
        state.graphOptions.fundHoverId = action.payload.id;
      }
    },
    removeSingleFundIdHidden: (
      state,
      action: PayloadAction<{ id: string }>
    ) => {
      state.graphOptions.fundIdsHidden =
        state.graphOptions.fundIdsHidden.filter((e) => e !== action.payload.id);
    },
    removeAllPicIdsHidden: (state) => {
      state.graphOptions.picIdsHidden = [];
    },
    removeSinglePicIdHidden: (state, action: PayloadAction<{ id: string }>) => {
      state.graphOptions.picIdsHidden = state.graphOptions.picIdsHidden.filter(
        (e) => e !== action.payload.id
      );
    },
    setFlags: (
      state,
      action: PayloadAction<{ start: number; end: number; revision?: boolean }>
    ) => {
      state.flags.start = parseInt(action.payload.start.toString());
      state.flags.end = parseInt(action.payload.end.toString());
      state.flags.revision =
        action.payload.revision === true
          ? state.flags.revision + 1
          : state.flags.revision;
      state.windowSize = Math.floor(
        DateTime.fromSeconds(state.flags.end).diff(
          DateTime.fromSeconds(state.flags.start),
          "months"
        ).months
      );
    },
    addSpotOperation: (
      state,
      action: PayloadAction<{ operation: SpotOperation }>
    ) => {
      // console.log(
      //   `[PIC][Add-SPOT] ${action.payload.operation.money} ${new Date(
      //     action.payload.operation.unix * 1000
      //   )} `
      // );
      if (action.payload.operation.money === 0) return;
      if (
        state.spots.operations
          .map((e) => e.unix)
          .indexOf(action.payload.operation.unix) === -1
      ) {
        state.spots.operations.push({
          ...action.payload.operation,
          id: `#spot-${state.spots.operations.length}`,
        });
      } else {
        state.spots.operations.push({
          ...action.payload.operation,
          id: `#spot-${state.spots.operations.length}`,
        });
        // state.spots.operations.forEach((e) => {
        //   e.money += action.payload.operation.money;
        // });
      }

      state.spots.operations.sort((a, b) => a.unix - b.unix);
    },
    removeSpotOperations: (state, action: PayloadAction<{ id: string }>) => {
      state.spots.operations = state.spots.operations.filter(
        (e) => e.id !== action.payload.id
      );
      state.spots.operations.sort((a, b) => a.unix - b.unix);
    },
    showSpot: (state) => {
      state.spots.operations = [];
      state.spots.showWindowSpot = true;
    },
    hideSpot: (state) => {
      state.spots.operations = [];
      state.spots.showWindowSpot = false;
    },
    changeSpotValue: (state, action: PayloadAction<{ value: number }>) => {
      state.spots.value = action.payload.value;
    },
    reset: (state) => {
      state.earnTable = undefined;
      state.flags = initialState.flags;
      state.graphOptions = initialState.graphOptions;
      state.money = initialState.money;
      state.spots = initialState.spots;
      state.windowSize = initialState.windowSize;
    },
  },
});

export const PICActions = picStore.actions;
export const PICReducer = picStore.reducer;

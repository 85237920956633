import firebase from "firebase";
import { ProviderAnalytics } from "./analytics";

export class AnalyticsFirebaseProivder implements ProviderAnalytics {
  private analyzer: firebase.analytics.Analytics;

  constructor(a: firebase.analytics.Analytics) {
    this.analyzer = a;
  }
  logEvent(name: string, obj: any): void {
    // console.log(`[EVENT] ${name}`, obj.type || "");
    this.analyzer.logEvent(name, obj);
  }
}

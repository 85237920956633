import { Investment, PACResponse, SimulationWithSpotOperation } from "../types";
import { SpotOperation } from "./redux";

export const NO_SPOT_ID = "#no-spot";

export function getInvestedArray(rates: Investment[]): number[] {
  let cache = 0;
  return rates.map<number>((e) => {
    if (cache + e.money < 0) {
      let diff = cache + e.money;
      e.money = e.money + diff * -1;
      cache += e.money;
      return cache;
    } else {
      cache += e.money;
      return cache;
    }
  });
}

export function pacGetInvestmentTimeToTime(pac: PACResponse): Investment[] {
  let snaps = pac.snaps.slice(1);
  const invests: Investment[] = snaps.map((e, index) => {
    // FIXME: qui controllo se l'investito nel giorno precendete è uguale all'attuale se questo accade significa che la simulazione
    // ha un differimento. Questo differiemtno deve bloccare l'ingresso di altri investimenti altrimenti il calcolo è errato.
    // NOTE: Questo bug si sistemerà nella nuova funzione SPOTCalc, questo è un fix rapido.
    if (snaps[index - 1] != undefined)
      if (e.investedMoney === snaps[index - 1].investedMoney) {
        return {
          unix: e.unix,
          money: 0,
        };
      }

    return {
      unix: e.unix,
      money: pac.request.rate,
    };
  });
  // Aggiungo l'investimento iniziale al giorno 1
  invests.unshift({
    unix: pac.snaps[0].unix,
    money: pac.request.initialInvestment,
  });
  return invests;
}

function simulationWithTheseSpotOperations(
  pac: PACResponse,
  investments: SpotOperation[],
  spot: SpotOperation,
  opts?: { debug?: boolean }
): SimulationWithSpotOperation {
  investments.sort((a, b) => a.unix - b.unix);
  //if (!this.pac) throw new Error("non puoi richiamare gli aggiuntivi se non hai richiesto una simulazione pac")

  const closes = pac.snaps.map<number[]>((e) => e.closes);
  const weights = pac.request.funds.map<number>((e) => e.weight);
  const moneyInvest = pacGetInvestmentTimeToTime(pac);

  if (moneyInvest.length === 0)
    throw new Error("Non posso procedere senza investimento");
  //if (moneyInvest.length !== closes.length  ) throw new Error(`Errore chiusure e pesi non corrispondono ${moneyInvest.length} ${closes.length}`)
  if (closes[0].length !== weights.length)
    throw new Error("i pesi dei fondi non combacianao con le chiusure");

  // moneyInvest è un array che contiene gli investimenti rata per rata, ma identifica solo la rata di quel momento
  moneyInvest.forEach((m, i) => {
    if (investments.map((e) => e.unix).indexOf(m.unix) !== -1) {
      // per ottenre il grafico coerente, inserisco i soldi il giorno dopo.
      if (moneyInvest[i] !== undefined) {
        moneyInvest[i].money +=
          investments[investments.map((e) => e.unix).indexOf(m.unix)].money;
      }
    }
  });

  // calcolo l'investito nel tempo, a differenza della rata questo numero è cumulativo
  const invested = getInvestedArray(moneyInvest);

  const snap: SimulationWithSpotOperation = {
    days: [],
    weights: weights,
    spots: investments,
  };
  const quotes: number[] = closes[0].map((e) => 0);

  if (
    closes.length !== moneyInvest.length ||
    moneyInvest.length !== invested.length
  ) {
    throw new Error(`non coincidono i dati         
        ${moneyInvest.map((e) => e.money).length} - ${invested.length} ${
      closes.length
    }
        ${moneyInvest.map((e) => new Date(e.unix * 1000))}
        `);
  }
  closes.forEach((day, index) => {
    let negativeSpot: number[] = [];
    snap.days.push({
      closes: day,
      funds: day.map((e, i) => {
        // TODO:  moneyInvest[index].money rappresneta l'investimento diq quel giorno
        // se questo numero è negativo quindi significa che è un pèrelievo
        let q = (weights[i] * moneyInvest[index].money) / e;
        // controllo se il numero di quote diventa negativo
        if (quotes[i] + q < 0) {
          negativeSpot.push(quotes[i] * e);
          quotes[i] = 0;
        } else {
          negativeSpot.push(0);
          quotes[i] += q;
        }
        return quotes[i] * e;
      }),
      earns: day.map((e, i) => {
        let cv = quotes[i] * e;
        if (negativeSpot[i] !== 0) {
          return negativeSpot[i] - invested[index - 1] * weights[i];
        }
        return cv - invested[index] * weights[i];
      }),
      invested: weights.map((e) => invested[index] * e),
      rate: weights.map((e) => moneyInvest[index].money * e),
      quotes: [...quotes],
      unix: moneyInvest[index].unix,
    });
    if (opts) {
      if (opts.debug || true) {
        // console.log(snap.days[snap.days.length - 1]);
      }
    }
  });
  return snap;
}

export function calcSpotOperation(
  pac: PACResponse,
  spots: SpotOperation[]
): SimulationWithSpotOperation[] {
  const consumedSpot: SpotOperation[] = [];

  const result = spots.map<SimulationWithSpotOperation>((spot, index) => {
    const result = simulationWithTheseSpotOperations(
      pac,
      [...consumedSpot, spot],
      spot
    );
    consumedSpot.push(spot);
    return result;
  });
  let noSpot = {
    id: NO_SPOT_ID,
    money: 0,
    unix: pac.request.start || 0,
  };
  result.unshift(simulationWithTheseSpotOperations(pac, [noSpot], noSpot));
  pac.simulationsWithSpots = result;
  return result;
}

// import { Investment, PACResponse, SimulationWithSpotOperation } from "../types";
// import { SpotOperation } from "./redux";

// export const NO_SPOT_ID = "#no-spot";

// export function getInvestedArray(rates: Investment[]): number[] {
//   let cache = 0;
//   return rates.map<number>((e) => {
//     if (cache + e.money < 0) {
//       // let diff = cache + e.money;
//       // e.money = e.money + diff * -1;
//       // cache += e.money;
//       return cache;
//     } else {
//       cache += e.money;
//       return cache;
//     }
//   });
// }

// export function getSumPositiveSpot(rates: Investment[]): number[] {
//   let money = 0;
//   return rates.map((e) => {
//     if (e.money > 0) {
//       money += e.money;
//     }
//     return money;
//   });
// }

// export function getSumNegativeSpot(rates: Investment[]): number[] {
//   let money = 0;
//   return rates.map((e) => {
//     if (e.money < 0) {
//       money += Math.abs(e.money);
//     }
//     return money;
//   });
// }

// function getInvestmentTimeToTime(pac: PACResponse): Investment[] {
//   const invests: Investment[] = pac.request.investments.map((e) => {
//     return {
//       unix: e.unix,
//       money: e.money || 0,
//     };
//   });
//   // Aggiungo l'investimento iniziale al giorno 1
//   invests.unshift({
//     unix: pac.request.start,
//     money: pac.request.initialInvestment.money,
//   });
//   return invests;
// }

// function simulationWithTheseSpotOperations(
//   pac: PACResponse,
//   investments: SpotOperation[],
//   spot: SpotOperation,
//   opts?: { debug?: boolean }
// ): SimulationWithSpotOperation {
//   investments.sort((a, b) => a.unix - b.unix);
//   //if (!this.pac) throw new Error("non puoi richiamare gli aggiuntivi se non hai richiesto una simulazione pac")

//   const closes = pac.snaps.map<number[]>((e) => e.closes);
//   const weights = pac.request.funds.map<number>((e) => e.weight);
//   const moneyInvest = getInvestmentTimeToTime(pac);

//   if (moneyInvest.length === 0)
//     throw new Error("Non posso procedere senza investimento");
//   //if (moneyInvest.length !== closes.length  ) throw new Error(`Errore chiusure e pesi non corrispondono ${moneyInvest.length} ${closes.length}`)
//   if (closes[0].length !== weights.length)
//     throw new Error("i pesi dei fondi non combacianao con le chiusure");

//   // moneyInvest è un array che contiene gli investimenti rata per rata, ma identifica solo la rata di quel momento
//   moneyInvest.forEach((m, i) => {
//     if (investments.map((e) => e.unix).indexOf(m.unix) !== -1) {
//       // per ottenre il grafico coerente, inserisco i soldi il giorno dopo.
//       if (moneyInvest[i] !== undefined) {
//         moneyInvest[i].money +=
//           investments[investments.map((e) => e.unix).indexOf(m.unix)].money;
//       }
//     }
//   });

//   // calcolo l'investito nel tempo, a differenza della rata questo numero è cumulativo
//   const invested = getInvestedArray(moneyInvest);
//   //invested[0] = moneyInvest[0].money;
//   const nSpot = getSumNegativeSpot(moneyInvest);
//   //const pSpot = getSumPositiveSpot(moneyInvest);
//   const snap: SimulationWithSpotOperation = {
//     days: [],
//     weights: weights,
//     spots: investments,
//   };
//   const quotes: number[] = closes[0].map((e) => 0);

//   if (
//     closes.length !== moneyInvest.length ||
//     moneyInvest.length !== invested.length
//   ) {
//     throw new Error(`non coincidono i dati
//         ${moneyInvest.map((e) => e.money).length} - ${invested.length} ${
//       closes.length
//     }
//         ${moneyInvest.map((e) => new Date(e.unix * 1000))}
//         `);
//   }

//   closes.forEach((day, index) => {
//     let negativeSpotValue = 0;
//     snap.days.push({
//       closes: day,
//       funds: day.map((e, i) => {
//         // TODO:  moneyInvest[index].money rappresneta l'investimento diq quel giorno
//         // se questo numero è negativo quindi significa che è un pèrelievo
//         let q = (weights[i] * moneyInvest[index].money) / e;
//         // controllo se il numero di quote diventa negativo
//         if (quotes[i] + q < 0) {
//           negativeSpotValue += quotes[i] * e;
//           quotes[i] = 0;
//         } else {
//           quotes[i] += q;
//         }
//         return quotes[i] * e;
//       }),
//       earns: day.map((e, i) => {
//         //  (controvalore corrente + somma dei prelievi effettuati) - (somma dei versamenti effettuati) = guadagno o perdita
//         if (negativeSpotValue !== 0) {
//           for (let i = index; i < closes.length; i++) {
//             nSpot[i] = negativeSpotValue;
//           }
//           negativeSpotValue = 0;
//         }
//         let cv = quotes[i] * e;
//         //Negative spot
//         let ns = (nSpot[index] || 0) * weights[i];
//         let ps = (invested[index] || 0) * weights[i];
//         let r = cv + ns - ps;
//         console.log(
//           `${spot.id} ${new Date(
//             moneyInvest[index].unix * 1000
//           )} CV:${cv} + NS:${ns.toFixed(2)} - PS:${ps.toFixed(2)} = ${r.toFixed(
//             2
//           )}`
//         );
//         return r;
//         // return cv - invested[index] * weights[i];
//       }),
//       invested: weights.map((e) => invested[index] * e),
//       rate: weights.map((e) => moneyInvest[index].money * e),
//       quotes: [...quotes],
//       unix: moneyInvest[index].unix,
//     });
//     if (opts) {
//       if (opts.debug || true) {
//         console.log(snap.days[snap.days.length - 1]);
//       }
//     }
//   });
//   return snap;
// }

// export function calcSpotOperation(
//   pac: PACResponse,
//   spots: SpotOperation[]
// ): SimulationWithSpotOperation[] {
//   const consumedSpot: SpotOperation[] = [];

//   const result = spots.map<SimulationWithSpotOperation>((spot, index) => {
//     const result = simulationWithTheseSpotOperations(
//       pac,
//       [...consumedSpot, spot],
//       spot
//     );
//     consumedSpot.push(spot);
//     return result;
//   });
//   let noSpot = {
//     id: NO_SPOT_ID,
//     money: 0,
//     unix: pac.request.start || 0,
//   };
//   result.unshift(simulationWithTheseSpotOperations(pac, [noSpot], noSpot));
//   pac.simulationsWithSpots = result;
//   return result;
// }

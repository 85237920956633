import { store } from "contorller";
import { PortfolioWithFunction } from "contorller/portfolio/types";
import { ConfrontableActions } from "./redux";

export interface ConfrontableController {
  addToconfrontable(p: PortfolioWithFunction): void;
  removeConfrontable(id: string): void;
  updateConfrontable(p: PortfolioWithFunction): void;
  clearAll(): void;
}

class controller implements ConfrontableController {
  clearAll(): void {
    store.dispatch(ConfrontableActions.cleanAllPortfolio());
  }
  addToconfrontable(p: PortfolioWithFunction): void {
    store.dispatch(ConfrontableActions.addPortfolio({ portfolio: p }));
  }
  updateConfrontable(p: PortfolioWithFunction): void {
    store.dispatch(ConfrontableActions.updatePortfolio({ portfolio: p }));
  }
  removeConfrontable(id: string): void {
    // console.log("remove", id);
    store.dispatch(ConfrontableActions.removePortfolio({ ID: id }));
  }
}

export const confortableController = () => new controller();
